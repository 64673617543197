import React, { useState } from "react";
import "./Accordion.css";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Highlighted from "../highliter/Highlither";

function Accordion({
    heading,
    content,
    searchPhrase = "",
    link = false,
}) {
    const [isActive, setIsActive] = useState(false);

    return (
        <li className="accordion-item">
            <div className="accordion-text-wrapper">
                <div className="accordion-toggle" onClick={() => setIsActive(!isActive)}>
                    <i className={isActive ? "hide-icon" : "expand-icon"} />
                    {" "}
                    <div className="accordion-head">
                        <Trans>
                            <Highlighted
                                text={heading}
                                highlight={searchPhrase}
                            />
                        </Trans>
                    </div>
                </div>
                {isActive && (
                    <p className="accordion-content">
                        {!searchPhrase
                            ? (
                                <Trans>
                                    {content}
                                </Trans>
                            )
                            : (
                                <Trans>
                                    <Highlighted
                                        text={content}
                                        highlight={searchPhrase}
                                    />
                                </Trans>
                            )}
                    </p>
                )}
            </div>
            {link && <Link className="accordion-link" to={link} />}
        </li>
    );
}

export default Accordion;
