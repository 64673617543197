import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import "./NotFound.css";

export default function NotFound() {
    const { t } = useTranslation("notFound");
    return (
        <Layout>
            <div className="notFoundContainer">
                <div className="notFoundIcon" />
                <div className="erroCodeText">
                    404
                </div>
                <div className="errorText">
                    {t("pageNotFound")}
                </div>
            </div>
        </Layout>
    );
}
