import React from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../accordion/Accordion";

export default function DetailInfo({ tab }) {
    const { t } = useTranslation("detailsInfo");
    const obj = t(tab, { returnObjects: true });
    const dict = Object.keys(obj)
        .map((key) => ({
            key,
            value: obj[key],
        }));
    const items = dict.map((item) => (
        <div key={item.key}>
            <Accordion heading={item.key} content={item.value} />
        </div>
    ));
    return <div className="article-wrapper">{items}</div>;
}
