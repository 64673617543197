const dev = {
    API_BASE_URL: "http://localhost:8000/api/v1/",
};

const prod = {
    API_BASE_URL: process.env.REACT_APP_API_URL,
};

const config = {
    ...(process.env.REACT_APP_API_URL === undefined ? dev : prod),
};

export default config;
