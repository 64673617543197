import React from "react";
import "./Article.css";
import { withTranslation } from "react-i18next";

const MAX_MESSAGE_SIZE = 300;

function Article({
    t,
    item,
}) {
    const tags = item?.message_tags
        ?.filter((m) => m?.name?.length < 15)?.slice(0, 2)?.map((tag) => (
            <div key={tag.id} className="article-tag">
                {tag.name}
            </div>
        ));

    const openInNewTab = (path) => {
        window.open(path, "_blank", "noreferrer");
    };
    return (

        <div
            onClick={() => openInNewTab(item.permalink_url)}
            key={item.id}
            className="article-allPlates"
        >
            <div>
                <img alt="1" src={item?.full_picture} className="article-preview" />
                <div className="article-content">
                    <div className="article-tags">
                        {tags}
                    </div>
                    <div className="article-headline">
                        {item?.message?.length > MAX_MESSAGE_SIZE + 100
                            ? (
                                <>
                                    {item?.message?.slice(0, MAX_MESSAGE_SIZE)}
                                    {"... "}
                                    <a href={item?.permalink_url} target="_blank" rel="noreferrer"> Більше</a>
                                </>
                            )
                            : item?.message}
                    </div>
                </div>
            </div>
            <div className="article-Alldates">
                <div className="article-allHeadlineDate" />
                <div className="article-date">
                    {t("displayDate", { date: item.created_time })}
                </div>
            </div>
        </div>
    );
}

export default withTranslation("allNews")(Article);
