import React from "react";
import "./Contacts.css";
import { Trans, useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";

function Contacts() {
    const { t } = useTranslation("contacts");
    return (
        <Layout>
            <div className="wrapper">
                <div className="image-container">
                    <div className="image-labels-wrapper">
                        <div className="image-label">
                            {" "}
                            {t("contacts")}
                        </div>
                    </div>
                </div>
                <div className="second-row">
                    <div className="contactsPlate">
                        <div className="phonesFirstRow">
                            <div className="phoneIcon" />
                            {t("phones")}
                        </div>
                        <div className="phonesSecondRow">
                            <div>
                                (048) 779-82-04
                            </div>
                            <div>
                                (048) 779-82-06
                            </div>

                        </div>
                    </div>
                    <div className="contactsPlate">
                        <div className="addressFirstRow">
                            <div className="addressIcon" />
                            {t("address")}
                        </div>
                        <div className="addressSecondRow">
                            <Trans>{t("addressValue")}</Trans>
                        </div>
                    </div>
                    <div className="contactsPlate">
                        <div className="emailFirstRow">
                            <div className="emailIcon" />
                            EMAIL
                        </div>
                        <div className="emailSecondRow">
                            admindep@navy.mil.gov.ua
                        </div>
                    </div>
                </div>
                <div className="thirdRow">
                    <div className="paragraphHead">
                        <div className="text-blue">
                            <Trans>
                                {t("paragraphHeadBlue")}
&nbsp;
                            </Trans>
                        </div>
                        <div className="text-black-contacts">{t("paragraphHeadBlack")}</div>
                    </div>
                    <div className="paragraphText">
                        {t("paragraphFirst")}
                        <br />
                        <br />
                        {t("paragraphSecond")}
                    </div>
                </div>
            </div>
        </Layout>

    );
}

export default Contacts;
