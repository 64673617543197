import React from "react";
import "./NavyFace.css";
import { withTranslation } from "react-i18next";

function NavyFace({ t }) {
    return (
        <div className="wrapper-navy-face">
            <div className="soldier-background fade-in-animation middle-animation" />
            <div className="navy-face-text">
                <span className="navy-face-header fade-in-animation fast-animation">
                    {t("VM")}
                    <br />
                    {t("S")}
                    <span className="navy-face-subheader fade-in-animation last-animation">
                        {t("abbreviation")}
                    </span>
                    <i className="anchor-icon last-animation" />
                </span>
                <div className="navy-face-info">
                    <div className="navy-face-info-content">{t("info")}</div>
                </div>
            </div>
        </div>
    );
}
export default withTranslation("navyFace")(NavyFace);
