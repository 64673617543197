import React from "react";
import "./CareerMain.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";

export default function CareerMain() {
    const { t } = useTranslation("career");
    const navigate = useNavigate();

    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    async function handleClick(event, route) {
        event.preventDefault();
        scrollToTop();
        navigate(route);
    }

    return (
        <Layout>
            <div className="wrapper">
                <div className="image-container">
                    <div className="image-labels-wrapper">
                        <div className="image-label">{t("career")}</div>
                        <div className="path">
                            <Link onClick={() => scrollToTop()} className="path-label" to="/">{t("main")}</Link>
                            &nbsp;&nbsp; | &nbsp;&nbsp;
                            <Link className="path-label-inactive" to="/career">{t("career")}</Link>
                        </div>
                    </div>
                </div>
                <div className="main-zone">
                    <div onClick={(e) => handleClick(e, "../career/vacancees")} className="career-card">
                        <div className="vacancees-icon" />
                        <div className="card-label">
                            {t("vacancees")}
                        </div>
                        <div className="separator" />
                        <div className="details-wrapper">
                            <div className="details-label">
                                {t("details")}
                                {" "}
                                &#10095;
                            </div>
                        </div>
                    </div>
                    <div onClick={(e) => handleClick(e, "../career/contract-service")} className="career-card">
                        <div className="contract-icon" />
                        <div className="card-label">
                            {t("contract")}
                        </div>
                        <div className="separator" />
                        <div className="details-wrapper">
                            <div className="details-label">
                                {t("details")}
                                {" "}
                                &#10095;
                            </div>
                        </div>
                    </div>
                    <div onClick={(e) => handleClick(e, "../career/recruitment-centers")} className="career-card">
                        <div className="centers-icon" />
                        <div className="card-label">
                            {t("centers")}
                        </div>
                        <div className="separator" />
                        <div className="details-wrapper">
                            <div className="details-label">
                                {t("details")}
                                {" "}
                                &#10095;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
