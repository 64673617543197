/* eslint-disable max-len */
import React from "react";
import "./News.css";
import { withTranslation } from "react-i18next";
import useGetFacebookFeed from "../../hooks/useGetFacebookFeed";
import Spinner from "../spinner";
import Article from "./Article";

function News({ t }) {
    const {
        articles, loading, handleLoadMore, nextUrl, postsNumber,
    } = useGetFacebookFeed();

    return (
        <div className="wrapper-news">
            <div className="main-page-news-header">
                {t("newsTitle")}
                &nbsp;
                <span className="main-page-news-subheader">{t("newsSubTitle")}</span>
            </div>

            <div className="main-content-news">
                <div className="main-content">

                    <div className="news">
                        {articles?.slice(0, postsNumber).map((article) => <Article item={article} key={article?.id} t={t} />)}
                    </div>
                </div>
            </div>
            {loading && <Spinner />}

            <div onClick={(e) => handleLoadMore(e, nextUrl)} className="more-news-container">
                <div className="more-news-icon" />
                <div className="more-new-text">
                    {t("moreNews")}
                </div>
            </div>
        </div>
    );
}

export default withTranslation("newsMainPage")(News);
