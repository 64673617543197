import React from "react";
import { useTranslation } from "react-i18next";

export default function Sailors() {
    const { t } = useTranslation("vacanceesMain");
    return (
        <div>
            <div className="vacanceWrapper">
                <div className="sailor-image" />
                <div className="info-wrapper">
                    <div className="vacance-head">{t("private")}</div>
                    <div className="vacance-separator" />
                    <div className="vacance-info">{t("privateText")}</div>
                </div>
            </div>
        </div>
    );
}
