import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ellipse from "../../assets/ellipse.svg";
import "./Sidebar.css";

function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
}

function Sidebar({ t }) {
    const [menuActive, setMenuActive] = useState(false);

    function menuOnClick(event) {
        if (event.currentTarget.className === "menu-active") {
            setMenuActive(false);
        }
    }

    return (
        <div className="hamburger-menu-wrapper">
            <div
                onClick={() => setMenuActive(!menuActive)}
                className={menuActive ? "hamburger-menu open" : "hamburger-menu"}
            >
                <span />
                <span />
                <span />
            </div>
            <div className={menuActive ? "menu-active" : "hide"} onClick={(e) => menuOnClick(e)}>
                <div className="burger-menu-content" onClick={(e) => e.stopPropagation()}>
                    <Link onClick={scrollToTop} className="nav-item" to="/news">{t("news")}</Link>
                    <img alt="ellipse" src={ellipse} />
                    <Link
                        onClick={() => {
                            setMenuActive(false);
                            setTimeout(() => {
                                const coms = document.getElementById("commanders");
                                coms.scrollIntoView({ behavior: "smooth" });
                            }, 100);
                        }}
                        className="nav-item"
                        to="/"
                    >
                        {t("about")}
                    </Link>
                    <img alt="ellipse" src={ellipse} />
                    <Link
                        onClick={scrollToTop}
                        className="nav-item"
                        to="/career"
                    >
                        {t("career")}
                    </Link>
                    <img alt="ellipse" src={ellipse} />
                    <Link
                        onClick={scrollToTop}
                        className="nav-item"
                        to="/details"
                    >
                        {t("details")}
                    </Link>
                    <img alt="ellipse" src={ellipse} />
                    <Link
                        onClick={scrollToTop}
                        className="nav-item"
                        to="/contacts"
                    >
                        {t("contacts")}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default withTranslation("sidebar")(Sidebar);
