import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import DetailInfo from "../../../components/detailInfo/DetailInfo";
import "./ContractService.css";

function ContractService({ t }) {
    return (
        <Layout>
            <div className="image-container">
                <div className="image-labels-wrapper">
                    <div className="image-label">
                        <span className="">{t("milf")}</span>
                        {" "}
                        {t("service")}
                    </div>
                    <div className="path">
                        <Link className="path-label" to="/">{t("main")}</Link>
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <Link className="path-label" to="/career">{t("career")}</Link>
                        <div>
&nbsp;&nbsp; | &nbsp;&nbsp;
                            <a href="/" className="path-label-inactive">
                                <span className="">{t("milf")}</span>
                                {" "}
                                {t("service")}
                            </a>
                            {" "}

                        </div>
                    </div>
                </div>
            </div>
            <div className="contract-service-wrapper">
                <div className="contract-service-info">
                    <div className="four-row-career">
                        <div className="addElement">
                            <DetailInfo tab="infoCareer" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default withTranslation("serviceContract")(ContractService);
