import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SearchResults.css";
import Layout from "../../components/layout/Layout";
import Accordion from "../../components/accordion/Accordion";
import Pagination from "../../components/pagination/Pagination";
import getNewsList from "../../services/Search";
import Highlighted from "../../components/highliter/Highlither";

function SearchResults({
    t,
    i18n,
}) {
    const location = useLocation();
    const navigate = useNavigate();

    const {
        searchPhrase,
        staticResults,
        news,
        newsCount,
    } = location.state || {};
    const [newsList, setNewsList] = useState(news);
    const [pageSize, setPageSize] = useState(9);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(Math.ceil((newsCount / pageSize)));

    useEffect(() => {
        if (!searchPhrase) {
            navigate("/404");
        }
    }, []);

    function renderStaticResults() {
        const results = [];
        results.push(staticResults.map((el) => (
            <Accordion
                heading={el.title}
                content={el.preview}
                searchPhrase={el.phrase}
                link={el.url}
            />
        )));
        return results;
    }

    async function openNews(event, route) {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        navigate(route);
    }

    function renderNews() {
        const results = [];
        if (newsList) {
            results.push(newsList.map((el) => (
                <div>
                    <div
                        onClick={(e) => openNews(e, `/news/${el.guid}`)}
                        key={el.id}
                        className="article-allPlates"
                    >
                        <img
                            alt="1"
                            src={el.preview_image}
                            className="article-preview"
                        />
                        <div className="article-content">
                            <div className="article-title">
                                <Highlighted
                                    text={el.title}
                                    highlight={searchPhrase}
                                />
                            </div>
                            <div
                                className="article-headline"
                            >
                                <Highlighted
                                    text={el.head_line}
                                    highlight={searchPhrase}
                                />
                            </div>
                        </div>
                        <div className="article-all-cotener-information ">
                            <div className="article-allHeadline">
                                <div className="article-allHeadlineEyes" />
                                <div
                                    className="article-headlines"
                                >
                                    {el.views_count}
                                </div>
                            </div>
                            <div className="article-Alldate">
                                <div className="article-allHeadlineDate" />
                                <div className="arcticle-date">
                                    {t("displayDate", { date: el.display_date })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )));
            return results;
        }
        return <div />;
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function showNotResultBlock() {
        if (newsList.length === 0 && staticResults.length === 0) {
            return (
                <div className="search-result-notFound">
                    <div className="search-warShip" />
                    <div className="search-main-notFound">
                        <div>{t("notSearchResultOne")}</div>
                        <div className="search-notFound-text-row">{searchPhrase}</div>
                        <div>{t("notSearchResultSecond")}</div>
                    </div>
                </div>
            );
        }
        return "";
    }

    useEffect(() => {
        setPagesCount(Math.ceil(newsCount / pageSize));
        getNewsList(i18n.language, pageSize, pageNumber, searchPhrase)
            .then((result) => {
                setNewsList(result);
                scrollToTop();
            });
    }, [searchPhrase, pageSize, pageNumber]);

    return (
        <Layout isSearchActive>
            <div className="wrapper-all-news">
                <div className="title-content-search">
                    {t("search")}
                    &nbsp;
                    <span className="title-black-accent">{t("results")}</span>
                </div>
                <div className="area-content">
                    <div className="static-results">
                        {staticResults && staticResults.length !== 0 && (
                            <div>
                                <h4 className="search-result-subheader">{t("articles")}</h4>
                                <div className="static-search-results">
                                    {renderStaticResults()}
                                </div>
                            </div>
                        )}
                    </div>

                    { staticResults && showNotResultBlock()}
                    {(newsList && newsList.length !== 0) && (
                        <div>
                            <h4 className="search-result-subheader">{t("news")}</h4>
                            <div className="newsSearchResults allNews">
                                {renderNews()}
                            </div>
                            <Pagination
                                pagesCount={pagesCount}
                                onPageSizeChanged={setPageSize}
                                pageNumber={pageNumber}
                                onPageNumberChanged={setPageNumber}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default withTranslation("searchResults")(SearchResults);
