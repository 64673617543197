import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./Search.css";
import API from "services/API";
import getNewsList from "services/Search";

function Search({
    t,
    i18n,
    toggleSearch,
}) {
    const [keys, setKeys] = useState([]);
    const [searchPhrase, setSearchPhrase] = useState("");
    const pageSize = 9;
    const pageNumber = 1;
    const inputElement = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    }, []);
    useEffect(() => {
        fetch("/locales/keys.json")
            .then((res) => res.json())
            .then((data) => {
                setKeys(data[i18n.language]);
            });
    }, [t]);

    function handleInputChange(e) {
        setSearchPhrase(e.target.value);
    }

    function findMatchInKeys(pageData, searchWord, staticResults, word) {
        for (const el of pageData.keys) {
            if (el.includes(searchWord)) {
                staticResults.push({
                    url: pageData.url,
                    title: pageData.title,
                    phrase: word,
                    preview: pageData.preview,
                });
                break;
            }
        }
    }

    function getStaticSearchResults() {
        const staticResults = [];
        const words = searchPhrase.split();
        words.forEach((word) => {
            const searchWord = word.trim()
                .toLowerCase();
            keys.forEach((pageData) => {
                findMatchInKeys(pageData, searchWord, staticResults, word);
            });
        });
        return staticResults;
    }

    function getNewsCount() {
        return API.get(`/search/count/?language=${i18n.language}&search_phrase=${searchPhrase}`)
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                return 0;
            })
            .catch(() => 0);
    }

    async function search(e) {
        e.preventDefault();
        const staticResults = getStaticSearchResults();
        const [newsCount, news] = await Promise.all([
            getNewsCount(),
            getNewsList(i18n.language, pageSize, pageNumber, searchPhrase),
        ]);
        navigate("/search-results", {
            state: {
                searchPhrase,
                staticResults,
                news,
                pageSize,
                pageNumber,
                newsCount,
            },
        });
    }

    return (
        <div className="search">
            <div className="search-form-left-content">
                <div
                    className="cross-icon"
                    onClick={() => {
                        setSearchPhrase("");
                        toggleSearch(false);
                    }}
                />
                <form
                    id="search-form"
                    className="search-form"
                    onSubmit={(e) => search(e)}
                >
                    <input
                        id="search-input"
                        onChange={handleInputChange}
                        className="search-input"
                        type="search"
                        placeholder="Search..."
                        value={searchPhrase}
                        ref={inputElement}
                    />
                </form>
            </div>
            <div className="search-icon" onClick={(e) => search(e)} />
        </div>
    );
}

export default withTranslation("search")(Search);
