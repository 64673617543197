import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {
    format as formatDate,
    isDate,
} from "date-fns";

import { enUS, uk } from "date-fns/locale";

const locales = { enUS, uk };

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: ["ua", "en"],
        detection: {
            // order: ["path", "localStorage", "htmlTag", "cookie"],
            caches: ["localStorage", "cookie"],
        },
        lng: "ua",
        interpolation: {

            escapeValue: false,
            format: (value, format, lng) => {
                const date = new Date(value);
                if (isDate(date)) {
                    const locale = lng === "ua" ? locales.uk : locales.enUS;
                    if (format === "long") {
                        return formatDate(date, "PP", { locale });
                    }
                }
                return value;
            },
        },
    }, () => {
    });

export default i18n;
