import React from "react";
import "./Highlither.css";

function Highlighted({
    text = "",
    highlight = "",
}) {
    if (!highlight.trim()) {
        return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);

    return (
        <span>
            {parts.filter(String)
                .map((part, i) => (regex.test(part) ? (
                    <mark className="highlight" key={i}>{part}</mark>
                ) : (
                    <span key={i}>{part}</span>
                )))}
        </span>
    );
}

export default Highlighted;
