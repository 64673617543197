import React from "react";
import "./Career.css";
import { withTranslation } from "react-i18next";
import DetailInfo from "../detailInfo/DetailInfo";

function Career({ t }) {
    return (
        <div className="wrapper-career">
            <div className="right-column-career">
                <div className="groupe-career">
                    <div className="one-row-career">
                        <span className="color-span">{t("milf")}</span>
                        {" "}
                        {t("service")}
                    </div>
                    <div className="two-row-career">
                        {t("twoRowCareer")}
                    </div>
                    <div className="four-row-career">
                        <div className="add-element accordion-content">
                            <DetailInfo tab="infoCareer" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation("serviceContract")(Career);
