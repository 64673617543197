import React from "react";
import "./StructureNavy.css";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import shipStructure from "../../assets/shipStructure.svg";
import lines from "../../assets/lines.svg";
import lineSmall from "../../assets/lineSmall.svg";

function StructureNavy({ t }) {
    const navigate = useNavigate();
    return (
        <div className="wrapper-structure">
            <div className="top-structure">
                <div className="leff-columsStructure">
                    <div className="colums-oneTop">{t("columsOneTop")}</div>
                    <span> </span>
                    <div className="colums-twoTop">
                        &nbsp;
                        {t("columsTwoTop")}
                    </div>
                </div>
                <div className="right-columsStructure">
                    <img className="ship-structure" src={shipStructure} alt="shipStructure" />
                </div>
            </div>
            <div className="top-structure">
                <button type="button" className="button-commander" onClick={() => navigate("/commander")}>Командування ВМС</button>
            </div>
            <img src={lines} alt="lines" />
            <div className="rowInformation rowOneInformation">
                <div>{t("rowOneAlpha")}</div>
                <div>{t("rowTwoAlpha")}</div>
                <div>{t("rowThreeAlpha")}</div>
                <div>{t("rowFoureAlpha")}</div>
                <div>{t("rowFiveAlpha")}</div>
            </div>
            <img src={lines} alt="lines" />
            <div className="rowInformation rowTwoInformation">
                <div>{t("rowOneCenter")}</div>
                {/* <div></div> */}
                <div>{t("rowThreeCenter")}</div>
                <div>{t("rowFourCenter")}</div>
                <div>{t("rofFiveCenter")}</div>
            </div>
            <img src={lineSmall} alt="lineSmall" className="line-small" />
            <div className="rowInformation rowThreeInformation">
                <div>{t("rowOneBottom")}</div>
            </div>
        </div>
    );
}

export default withTranslation("structureNavy")(StructureNavy);
