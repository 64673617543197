import React from "react";
import "./LanguageState.css";
import { withTranslation } from "react-i18next";

function LanguageState({ i18n }) {
    const changeLanguage = () => {
        const val = i18n.language === "ua" ? "en" : "ua";
        i18n.changeLanguage(val.toLocaleLowerCase());
    };
    return (
        <div onClick={() => changeLanguage()} className="language-state">{i18n.language}</div>
    );
}

export default withTranslation()(LanguageState);
