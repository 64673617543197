import "./App.css";
import React, { Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import General from "./pages/general/General";
import Contacts from "./pages/contacts/Contacts";
import CareerMain from "./pages/career/CareerMain";
import VacanceesMain from "./pages/career/vacancees/VacanceesMain";
import Details from "./pages/details/Details";
import ContractService from "./pages/career/contractService/ContractService";
import RecruitmentCenters from "./pages/career/recruitmentCenters/RecruitmentCenters";
import NotFound from "./pages/notFound/NotFound";
import News from "./pages/news/News";
import DetailNews from "./pages/detailNews/DetailNews";
import SearchResults from "./pages/searchResults/SearchResults";
import Commander from "./pages/commander/Commander";

export default function App() {
    const { t } = useTranslation("app");
    useEffect(() => {
        document.title = t("title");
    }, []);
    return (
        <div>
            <Suspense>
                <BrowserRouter>
                    <ToastContainer autoClose={2000} hideProgressBar />
                    <Routes>
                        <Route path="/" element={<General />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/career" element={<CareerMain />} />
                        <Route path="/career/vacancees" element={<VacanceesMain />} />
                        <Route path="/details" element={<Details />} />
                        <Route path="/commander" element={<Commander />} />
                        <Route path="/career/contract-service" element={<ContractService />} />
                        <Route path="/career/recruitment-centers" element={<RecruitmentCenters />} />
                        <Route path="/news" element={<News />} />
                        <Route path="/news/:id" element={<DetailNews />} />
                        <Route path="/search-results" element={<SearchResults />} />
                        <Route path="/404" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}
