import React from "react";
import { withTranslation } from "react-i18next";
import "./RecruitmentCenters.css";
import { Link } from "react-router-dom";
import Layout from "components/layout/Layout";

function RecruitmentCenters({ t }) {
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    return (
        <Layout>
            <div className="image-container">
                <div className="image-labels-wrapper">
                    <div className="image-label">
                        {t("centers")}
                    </div>
                    <div className="path">
                        <Link onClick={() => scrollToTop()} className="path-label" to="/">{t("main")}</Link>
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <Link onClick={() => scrollToTop()} className="path-label" to="/career">{t("career")}</Link>
                        <div>
&nbsp;&nbsp; | &nbsp;&nbsp;
                            <a href="/" className="path-label-inactive">{t("centers")}</a>
                            {" "}
                        </div>
                    </div>
                </div>
            </div>
            <div className="vacance-wrapper">
                <div className="recruitment-plate">
                    <div className="recruitment-center-header">
                        {t("center")}
                        <br />
                        {t("odesa")}
                    </div>
                    <div className="recruitment-phone-header">
                        {t("numbers")}
                    </div>
                    <div className="recruitment-phones">
                        <div>+38(097)-041-17-55</div>
                        <div>+38(050)-211-88-72</div>
                        <div>+38(073)-485-53-39</div>
                        <div>+38(048)-779-80-13</div>
                        <div>+38(048)-779-82-62</div>
                    </div>
                    <div className="recruitment-address-header">
                        {t("adress")}
                    </div>
                    <div className="recruitment-address">
                        {t("odesaAddress")}
                    </div>
                    <div>
                        vcvms@ukr.net
                    </div>
                </div>
                <div className="recruitment-plate">
                    <div className="recruitment-center-header">
                        {t("center")}
                        <br />
                        {t("mikolaiiv")}
                    </div>
                    <div className="recruitment-phone-header">
                        {t("numbers")}
                    </div>

                    <div className="recruitment-phones">

                        <div>+38(063)-307-38-19</div>
                    </div>
                    <div className="recruitment-address-header">
                        {t("adress")}
                    </div>
                    <div className="recruitment-address">
                        {t("mikolaiivAddress")}
                    </div>
                    <div>
                        vcvms@ukr.net
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default withTranslation("recruitmentCenters")(RecruitmentCenters);
