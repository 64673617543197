import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../../../components/layout/Layout";
import "./VacanceesMain.css";
import Officers from "./Officers";
import Sergants from "./Sergants";
import Sailors from "./Sailors";

export default function VacanceesMain() {
    const { t } = useTranslation("vacanceesMain");
    const [vacance, setVacance] = useState(<Officers />);
    const [loc, setLoc] = useState(t("officers"));
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    useEffect(() => {
        setLoc(t("officers"));
        setVacance(<Officers />);
    }, [t]);

    return (
        <Layout>
            <div className="wrapper">
                <div className="vacancees-image-container">
                    <div className="image-labels-wrapper">
                        <div className="image-label">{t("vacancies")}</div>
                        <div className="path">
                            <Link onClick={() => scrollToTop()} className="path-label" to="/">{t("main")}</Link>
                            &nbsp;&nbsp; | &nbsp;&nbsp;
                            <Link onClick={() => scrollToTop()} className="path-label" to="/career">{t("career")}</Link>
                            <div>
&nbsp;&nbsp; | &nbsp;&nbsp;
                                <a href="/" className="path-label-inactive">{loc}</a>
                                {" "}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vacancees-second-row-wrapper">
                    <div className="career-nav">
                        <div
                            onClick={() => {
                                setVacance(<Officers />);
                                setLoc(t("officers"));
                            }}
                            className="vacancees-card"
                        >
                            <div className="officer-icon" />
                            <div className="vacancees-text">{t("officers")}</div>
                        </div>
                        <div
                            onClick={() => {
                                setVacance(<Sergants />);
                                setLoc(t("sergants"));
                            }}
                            className="vacancees-card"
                        >
                            <div className="sergant-icon" />
                            <div className="vacancees-text">
                                {t("sergants")}
                            </div>
                        </div>
                        <div
                            onClick={() => {
                                setVacance(<Sailors />);
                                setLoc(t("private"));
                            }}
                            className="vacancees-card"
                        >
                            <div className="sailor-icon" />
                            <div className="vacancees-text">{t("private")}</div>
                        </div>
                    </div>
                    <div className="career-main">
                        {vacance}
                    </div>
                </div>
            </div>
        </Layout>
    );
}
