import React from "react";
import "./Spinner.css";

function Spinner() {
    return (
        <div className="spinner">
            <div className="double-bounce1" />
            <div className="double-bounce2" />
        </div>
    );
}

export default Spinner;
