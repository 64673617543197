import React from "react";
import "./Layout.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import logoFacebook from "../../assets/facebook.svg";
import logoInstagram from "../../assets/instagram.svg";
import logoTwitter from "../../assets/twitter.svg";
import logoYouTube from "../../assets/youtube.svg";

function Layout({ children, isSearchActive }) {
    const logo = [
        {
            link: "https://www.facebook.com/navy.mil.gov.ua/",
            image: logoFacebook,
        },
        {
            link: "https://twitter.com/UA_NAVY",
            image: logoTwitter,
        },
        {
            link: "https://www.youtube.com/channel/UC2TIRA6ThhKnGikntI1UFkw",
            image: logoYouTube,
        },
        {
            link: "https://www.instagram.com/ua_navy/",
            image: logoInstagram,
        },
    ];

    return (
        <div>
            <Header logo={logo} isSearchActive={isSearchActive} />
            <main>{children}</main>
            <Footer logo={logo} />
        </div>
    );
}
export default Layout;
