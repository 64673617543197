import React from "react";
import "./Pagination.css";

function Pagination({
    pagesCount, onPageSizeChanged, pageNumber, onPageNumberChanged,
}) {
    return (
        <div className="pagination">
            <div className="page-control">

                <button
                    aria-label="navigate-back"
                    type="button"
                    className={pageNumber < 2 ? "navigateButton inactiveButton" : "navigateButton "}
                    onClick={() => {
                        onPageNumberChanged(pageNumber - 1);
                    }}
                    disabled={pageNumber < 2}
                />

                {pageNumber - 1 > 0
                    && (
                        <div
                            className="paginationBlock"
                            onClick={() => {
                                onPageNumberChanged(pageNumber - 1);
                            }}
                        >
                            {pageNumber - 1}
                        </div>
                    )}

                <div
                    className="paginationBlock paginationPageSelected"
                    onClick={() => {
                        onPageNumberChanged(pageNumber);
                    }}
                >
                    {pageNumber}
                </div>

                {pageNumber + 1 <= pagesCount
                    && (
                        <div
                            className="paginationBlock"
                            onClick={() => {
                                onPageNumberChanged(pageNumber + 1);
                            }}
                        >
                            {pageNumber + 1}
                        </div>
                    )}

                {pageNumber + 2 < pagesCount
                    && (
                        <div
                            className="paginationBlock"
                            onClick={() => {
                                onPageNumberChanged(pageNumber + 2);
                            }}
                        >
                            {pageNumber + 2}
                        </div>
                    )}

                {pageNumber + 2 < pagesCount
                    && (
                        <div
                            className="paginationBlock"
                        >
                            ...
                        </div>
                    )}

                <button
                    aria-label="navigate-forvard"
                    type="button"
                    className={pagesCount === pageNumber ? "navigateButton inactiveButton nextButton" : "navigateButton nextButton"}
                    onClick={() => {
                        onPageNumberChanged(pageNumber + 1);
                    }}
                    disabled={pagesCount === pageNumber}
                />

            </div>

            <select
                className="paginationBlock pageSizeDropdown"
                onChange={(event) => {
                    onPageSizeChanged(event.target.value);
                }}
            >
                <option value="9">9</option>
                <option value="18">18</option>
                <option value="27">27</option>
                <option value="36">36</option>
            </select>
        </div>
    );
}

export default Pagination;
