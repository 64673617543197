import React from "react";
import { useTranslation } from "react-i18next";

export default function Officers() {
    const { t } = useTranslation("vacanceesMain");
    return (
        <div className="officer-wrapper">
            <div className="officer-image" />
            <div className="info-wrapper">
                <div className="vacance-head">{t("officers")}</div>
                <div className="vacance-separator" />
                <div className="vacance-info">{t("officersText")}</div>
            </div>
        </div>
    );
}
