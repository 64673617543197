import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import "./DetailNews.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Layout from "../../components/layout/Layout";
import API from "../../services/API";
import "react-toastify/dist/ReactToastify.css";

function DetailNews({ i18n, t }) {
    const params = useParams();
    const [images, setImages] = useState([]);
    const [article, setArticle] = useState(null);
    const [news, setNews] = useState([]);
    const navigate = useNavigate();

    async function handleClick(event, route) {
        event.preventDefault();
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        navigate(route);
    }

    const loadArticle = () => {
        API.get(`/news/${params.id}?language=${i18n.language}`)
            .then((res) => {
                if (res.status === 200) {
                    setArticle(res.data);
                    if (res.data.images) {
                        setImages(res.data.images.split(";\n"));
                    } else {
                        setImages([]);
                    }
                }
            })
            .catch(() => {
                toast.error(t("news_not_found"));
                navigate("/not-found");
            });
    };

    useEffect(() => {
        loadArticle();
    }, [t]);

    useEffect(() => {
        loadArticle();
    }, [params.id]);

    useEffect(() => {
        API.get(`/news/?language=${i18n.language}&page_size=3&page_number=1&filter_tags=&date_sort_asc=false`)
            .then((response) => {
                if (response.status === 200) {
                    setNews(response.data);
                }
            })
            .catch(() => {
                setNews([]);
            });
    }, [article]);

    let tags;
    if (article) {
        tags = article.tags.map((tag) => (
            <div className="detail-article-tag">
                {i18n.language === "en" ? tag.name_en : tag.name}
            </div>
        ));
    }

    let moreNews;
    if (news) {
        moreNews = news.map((artcl) => {
            const articleTags = artcl.tags.map((tag) => (
                <div className="plate-content-tag">
                    {i18n.language === "en" ? tag.name_en : tag.name}
                </div>
            ));
            return (
                <div onClick={(e) => handleClick(e, `/news/${artcl.guid}?language=${i18n.language}`)} className="detail-news-plate">
                    <img alt="" className="detail-plate-preview" src={artcl.preview_image} />
                    <div className="news-plate-content">
                        <div>
                            <div className="plate-content-header">
                                {artcl.title}
                            </div>
                            <div className="plate-content-headline">
                                {artcl.head_line}
                            </div>
                        </div>
                        <div className="plate-content-bottom-row">
                            <div className="plate-content-tags">
                                {articleTags}
                            </div>
                            <div className="metadataWrapper">
                                <div className="detail-article-views-count">
                                    <div className="news-count-icon" />
                                    <div className="news-count">
                                        {artcl.views_count}
                                    </div>
                                </div>
                                <div className="detail-article-date">
                                    <div className="artilce-date-icon" />
                                    <div className="arcticle-date">
                                        {t("displayDate", { date: artcl.display_date })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    let carouselItems;
    if (images) {
        carouselItems = images.map((item, i) => (
            <div className="detail-carouse-item">
                <img alt={i} src={item} />
            </div>
        ));
    }

    return (
        <Layout>
            {article && (
                <div className="detail-news-container">
                    <div className="detail-news-header">
                        {t("news")}
                        {" "}
                        <span className="title-black-accent">{t("navy")}</span>
                    </div>
                    <div className="detail-news-wrapper">
                        <div className="detail-news-navigation">
                            <div onClick={(e) => handleClick(e, -1)} className="back-arrow" />
                            <div className="bread-crumbs">
                                <Link className="path-label text-black" to="/">
                                    {" "}
                                    {t("main")}
                                </Link>
                                &nbsp;&nbsp; | &nbsp;&nbsp;
                                <Link className="path-label text-black" to="/news">{t("news")}</Link>
                            </div>
                        </div>
                        <div className="detail-article-header">
                            {article.title}
                        </div>
                        <div className="detail-article-content-wrapper">
                            <div className="detail-article-first-row">
                                <div className="detail-article-tags">
                                    {tags}
                                </div>
                                <div className="metadataWrapper">
                                    <div className="detail-article-views-count">
                                        <div className="news-count-icon" />
                                        <div className="news-count">
                                            {article.views_count}
                                        </div>
                                    </div>
                                    <div className="detail-article-date">
                                        <div className="artilce-date-icon" />
                                        <div className="arcticle-date">
                                            {t("displayDate", { date: article.display_date })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: article.news_content }} className="detail-article-content" />

                        </div>

                        {images !== [] && (

                            <Carousel className="detail-article-images-wrapper">
                                {carouselItems}
                            </Carousel>

                        )}
                        {news.length !== 0
                            && (
                                <div className="detail-more-news-container">
                                    <div className="detail-article-more-news">
                                        {t("more_news")}
                                    </div>
                                    <div className="detail-more-news">
                                        {moreNews}
                                    </div>
                                </div>
                            )}

                    </div>
                </div>
            )}

        </Layout>
    );
}

export default withTranslation("detailNews")(DetailNews);
