import React, { useState } from "react";
import "./Commander.css";
import { withTranslation, Trans } from "react-i18next";
import Layout from "../../components/layout/Layout";

function Commander({ t }) {
    const firstCommander = { id: "firstCommander", name: t("detailedNameNeihpapa") };
    const secondCommander = { id: "secondCommander", name: t("detailedNameTarasov") };
    const thirdCommander = { id: "thirdCommander", name: t("detailedNameYrsol") };
    const [selectedCommander, setSelectedCommander] = useState(firstCommander);
    const [showDetails, setShowDetails] = useState(false);

    const showCommander = (commander) => {
        let show = true;
        if (showDetails) {
            show = false;
        }
        if (commander.id === selectedCommander.id) {
            show = true;
        }
        return show;
    };

    const selectCommander = (commander) => {
        setShowDetails(true);
        setSelectedCommander(commander);
    };

    const closeDetails = () => {
        setShowDetails(false);
    };

    return (
        <Layout>
            <div id="commanders" className="wrapper-commander">
                <div className="top-commander">
                    <div className="top-title-commander">
                        <h1 className="blue-title">
                            {t("titleOneRow")}
                            <span className="dark-title">
                                &nbsp;
                                {t("titleTwoRow")}
                            </span>
                        </h1>
                    </div>
                </div>
                <div className="container-commander">

                    {showCommander(firstCommander)
                        && (
                            <div className="commander-plate" id="firstCommander" onClick={() => { selectCommander(firstCommander); }}>
                                <div className="image-firstPlate" />
                                <div className="name-firstPlate">
                                    {t("nameNeihpapa")}
                                </div>
                                <div className="rank-firstPlate">
                                    {t("rankNeihpapa")}
                                </div>
                            </div>
                        )}

                    {showCommander(secondCommander)
                        && (
                            <div className="commander-plate" id="secondCommander" onClick={() => { selectCommander(secondCommander); }}>
                                <div className="image-secondPlate" />
                                <div className="name-firstPlate">
                                    <Trans>
                                        {t("nameTarasov")}
                                    </Trans>
                                </div>
                                <div className="rank-firstPlate">
                                    {t("rankTarasov")}
                                </div>
                            </div>
                        )}

                    {showCommander(thirdCommander)
                        && (
                            <div className="commander-plate" id="thirdCommander" onClick={() => { selectCommander(thirdCommander); }}>
                                <div className="image-thiredPlate" />
                                <div className="name-firstPlate">
                                    {t("nameYrsol")}
                                </div>
                                <div className="rank-firstPlate">
                                    {t("rankYrsol")}
                                </div>
                            </div>
                        )}

                    {showDetails
                        && (
                            <div className="text-plate">
                                <div className="name-firstPlate">
                                    <div>{selectedCommander.name}</div>
                                    <button type="button" aria-label="close" className="crossButton" onClick={() => { closeDetails(); }} />
                                </div>
                                <p className="text-plateDescription">
                                    {selectedCommander.id === "firstCommander" && t("descriptionNeihpapa")}
                                    {selectedCommander.id === "secondCommander" && t("descriptionTarasov")}
                                    {selectedCommander.id === "thirdCommander" && t("descriptionYrsol")}
                                </p>
                            </div>
                        )}

                </div>
            </div>
        </Layout>
    );
}

export default withTranslation("commander")(Commander);
