/* eslint-disable max-len */
import React from "react";
import "./News.css";
import { withTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import "react-toastify/dist/ReactToastify.css";
import Article from "../../components/article/Article";
import useGetFacebookFeed from "../../hooks/useGetFacebookFeed";
import Spinner from "../../components/spinner";

function News({
    t,
}) {
    const {
        articles, loading, handleLoadMore, nextUrl, postsNumber,
    } = useGetFacebookFeed();

    return (
        <Layout>
            <div className="wrapper-all-news">
                <div className="title-content">
                    {t("news")}
                    &nbsp;
                    <span className="title-black-accent">{t("navy")}</span>
                </div>
                <div className="area-content">
                    <div className="allNews">
                        {articles?.slice(0, postsNumber).map((article) => <Article item={article} t={t} />)}
                    </div>
                    {loading && <Spinner />}
                    <div onClick={(e) => handleLoadMore(e, nextUrl)} className="more-news-container">
                        <div className="more-news-icon-black" />
                        <div className="more-new-text">
                            {t("moreNews")}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default withTranslation("allNews")(News);
