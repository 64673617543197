import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import DetailInfo from "../../components/detailInfo/DetailInfo";
import "./Details.css";

function Details({ t }) {
    const [activeIndex, setActiveIndex] = useState(1);
    const handleClick = (index) => setActiveIndex(index);
    const checkActive = (index, className) => (activeIndex === index ? className : "");
    const shipOpen = () => window.open("https://ships.uanavy.site/");
    return (
        <Layout>
            <div className="wrapper">
                <div className="image-container">
                    <div className="image-labels-wrapper">
                        <div className="image-label">{t("add_info")}</div>
                    </div>
                </div>
                <div className="secondDetailsRow">
                    <div className="tabs">
                        <button
                            type="button"
                            className={`tab ${checkActive(1, "active")}`}
                            onClick={() => handleClick(1)}
                        >
                            {t("social")}
                        </button>
                        <button
                            type="button"
                            className={`tab ${checkActive(2, "active")}`}
                            onClick={() => handleClick(2)}
                        >
                            {t("money")}
                        </button>
                        <button
                            type="button"
                            className={`tab ${checkActive(3, "active")}`}
                            onClick={() => handleClick(3)}
                        >
                            {t("infoCareer")}
                        </button>
                        <button
                            type="button"
                            className={`tab ${checkActive(4, "active")}`}
                            onClick={() => handleClick(4)}
                        >
                            {t("veterans")}
                        </button>
                        <button
                            type="button"
                            className="tab"
                            onClick={() => shipOpen()}
                        >
                            {t("directory")}
                        </button>
                    </div>
                    <div className="panels">
                        <div className={`panel ${checkActive(1, "active")}`}>
                            <p className="panel-header">{t("social")}</p>
                            <DetailInfo tab="social" />
                        </div>
                        <div className={`panel ${checkActive(2, "active")}`}>
                            <p className="panel-header">{t("money")}</p>
                            <DetailInfo tab="money" />
                        </div>
                        <div className={`panel ${checkActive(3, "active")}`}>
                            <p className="panel-header">{t("infoCareer")}</p>
                            <DetailInfo tab="infoCareer" />
                        </div>
                        <div className={`panel ${checkActive(4, "active")}`}>
                            <p className="panel-header">{t("veterans")}</p>
                            <DetailInfo tab="veterans" />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default withTranslation("details")(Details);
