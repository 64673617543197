import API from "./API";

const getNewsList = (lang, pageSize, pageNumber, searchPhrase) => API.get(`/search/?language=${lang}&page_size=${pageSize}&page_number=${pageNumber}&search_phrase=${searchPhrase}`)
    .then((res) => {
        if (res.status === 200) {
            return res.data;
        }
        return [];
    })
    .catch(() => []);

export default getNewsList;
