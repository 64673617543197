import React from "react";
import { useTranslation } from "react-i18next";

export default function Sergants() {
    const { t } = useTranslation("vacanceesMain");
    return (
        <div>
            <div className="officer-wrapper">
                <div className="sergant-image" />
                <div className="info-wrapper">
                    <div className="vacance-head">{t("sergants")}</div>
                    <div className="vacance-separator" />
                    <div className="vacance-info">{t("sergantsText")}</div>
                </div>
            </div>
        </div>
    );
}
