import { useState, useEffect } from "react";

const ACCESS_TOKEN = "EABtuAbVdwK0BOxxQnjhSZCgKxGtWDpAwKyyCZAoEei3wKIZAa4v9TzLPFDc8USA8ZBfytPoZCFZBRrcV1FxJr0j1AN7ZAMHvuzTQycZCj2NQsaVUYXwFuH4WLqpx6gQk3glfewx3IAIH7XbfP0ZB3VxbEZCOTZCZBPmK4eezVKljf3NQ45QJyQiVRvZA6gK5lmzRtqj0il7har9nD3LXdFYQZD";

const POST_FIELDS = ["likes.summary(true)", "shares.summary(true)", "message", "attachments", "created_time", "permalink_url", "is_hidden", "full_picture", "message_tags"];

const PAGE_ID = "226387687564509";

const DEFAULT_POSTS_NUMBER = 6;

export default function useGetFacebookFeed() {
    const [articles, setArticles] = useState([]);
    const [nextUrl, setNextUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    const [postsNumber, setPostsNumber] = useState(DEFAULT_POSTS_NUMBER);

    const url = `https://graph.facebook.com/v20.0/${PAGE_ID}?access_token=${ACCESS_TOKEN}&fields=feed.limit(6){${POST_FIELDS.join(",")}}&filtering=[{field:"feed.is_hidden",operator:"EQUAL",value:false}]&format=json&method=get&origin_graph_explorer=1&pretty=0&suppress_http_code=1&transport=cors`;

    const fetchNextFeed = async (link) => {
        if (loading) return;
        setLoading(true);

        try {
            const response = await fetch(link, {
                method: "GET",
            });
            const result = await response.json();
            const newPosts = result.data?.filter((p) => !p.is_hidden);
            setArticles((prevFeed) => [...prevFeed, ...newPosts]);
            setNextUrl(result.paging.next || null);
        } catch (error) {
            console.error("Error fetching the Facebook feed:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchFeed = async () => {
        if (loading) return;
        setLoading(true);

        try {
            const response = await fetch(url, {
                method: "GET",
            });
            const result = await response.json();
            const newPosts = result.feed.data?.filter((p) => !p.is_hidden);
            setArticles((prevFeed) => [...prevFeed, ...newPosts]);
            if (newPosts.length < postsNumber) {
                fetchNextFeed(result.feed.paging.next);
            } else {
                setNextUrl(result.feed.paging.next || null);
            }
        } catch (error) {
            console.error("Error fetching the Facebook feed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFeed();
    }, []);

    async function handleLoadMore(event, route) {
        event.preventDefault();
        fetchNextFeed(route).finally(() => {
            setPostsNumber((prevNumber) => prevNumber + DEFAULT_POSTS_NUMBER);
        });
    }

    return {
        articles, handleLoadMore, nextUrl, loading, postsNumber,
    };
}
